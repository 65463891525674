import { NgClass, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Output } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faStop, faPlay, faXmark, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from '@cloudfactorydk/ng2-tooltip-directive';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.sass'],
  imports: [NgClass, NgIf, FaIconComponent, FormsModule, TooltipModule]
})
export class AudioComponent {
  public faCircleIcn = faMicrophone;
  public faStopIcn = faStop;
  public faPlayIcn = faPlay;
  public faXmarkIcn = faXmark;
  isRecording = false;
  audioUrl: string | null = null;
  recordingSizeMB: number | null = null; // New property to store the size in MB
  recordingTime = 0; // Timer in seconds
  private mediaRecorder: MediaRecorder | null = null;
  private audioChunks: Blob[] = [];
  private audioBlob: Blob | null = null;
  private recordingTimeout: any = null;
  private timerInterval: any = null; // Interval for the timer
  @Output() record: EventEmitter<{blob:string, duration:number, size:number}> = new EventEmitter();

  constructor(private http: HttpClient) {}

  async startRecording() {
    try {
      // Request microphone access
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(stream);

      // Collect audio data chunks
      this.mediaRecorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
      };

      // Start recording
      this.mediaRecorder.start();
      this.isRecording = true;
      this.recordingTime = 0; // Reset the timer

      // Start the timer
      this.timerInterval = setInterval(() => {
        this.recordingTime++;
      }, 1000);

      // Set a timeout to stop recording after 15 seconds
      this.recordingTimeout = setTimeout(() => {
        this.stopRecording();
        alert('Recording stopped automatically after 15 seconds.');
      }, 15000); // 15 seconds
    } catch (error) {
      console.error('Error accessing microphone:', error);
      alert('Microphone access is required to record audio.');
    }
  }

  resetRecording(){
    this.recordingSizeMB = null;
    this.audioUrl='';
    this.record.emit({blob:'', duration:0, size:0});
  }

  stopRecording() {
    this.isRecording = false;
    if (this.mediaRecorder) {
      this.recordingSizeMB = 0.1;
      this.mediaRecorder.stop();
      this.mediaRecorder.onstop = () => {
        // Combine audio chunks into a single Blob
        this.audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
        this.audioUrl = URL.createObjectURL(this.audioBlob);
        this.audioChunks = []; // Clear chunks for the next recording

        // Calculate the size of the recording in MB
        if (this.audioBlob) {
          this.recordingSizeMB = (this.audioBlob.size / (1024 * 1024)).toFixed(2) as unknown as number;

          const reader = new FileReader();
          reader.readAsDataURL(this.audioBlob);
          reader.onloadend = () => {
            const base64data = reader.result as string; // Base64-encoded string

            const payload = {
              fileName: 'recording.webm',
              fileData: base64data.split(',')[1] // Remove the Base64 prefix
            };
            this.record.emit({blob:payload.fileData, duration:this.recordingTime, size:this.audioBlob.size});

          }    

        }
      };

      // Clear the timer
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
      }

      // Clear the timeout if the recording is stopped manually
      if (this.recordingTimeout) {
        clearTimeout(this.recordingTimeout);
        this.recordingTimeout = null;
      }
    }else{
      this.recordingSizeMB = null;
    }
  }

  playAudio() {
    if (this.audioUrl) {
      const audio = new Audio(this.audioUrl);
      audio.play();
    }
  }

  uploadAudio() {
    if (this.audioBlob) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result as string; // Base64-encoded string

        // Prepare the payload
        const payload = {
          fileName: 'recording.webm',
          fileData: base64data.split(',')[1] // Remove the Base64 prefix
        };

        // Send the Base64 data to the server
        this.http.post('https://your-backend-endpoint.com/records', payload).subscribe({
          next: (response) => {
            console.log('Audio uploaded successfully:', response);
            alert('Audio uploaded successfully!');
          },
          error: (error) => {
            console.error('Error uploading audio:', error);
            alert('Failed to upload audio.');
          }
        });
      };

      // Read the Blob as a Base64 string
      reader.readAsDataURL(this.audioBlob);
    } else {
      alert('No audio to upload.');
    }
  }
}
