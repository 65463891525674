import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { saveAs } from 'file-saver';

import { HttpService } from 'src/app/services/http.service';
import { UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { MemoryStorage } from 'src/app/services/memory-storage.service';
import { NgClass, CurrencyPipe } from '@angular/common';
import { TooltipModule } from '@cloudfactorydk/ng2-tooltip-directive';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { BasketComponent } from '../basket/basket.component';
import { PaymentComponent } from '../payment/payment.component';
import { TranslateModule } from '@ngx-translate/core';
import { SafeHtmlPipe } from '../../pipe/safehtml.pipe';

import { JsonPipe } from '@angular/common';

@Component({
    templateUrl: './basket-modal.component.html',
    imports: [NgClass, TooltipModule, FaIconComponent, BasketComponent, PaymentComponent, CurrencyPipe, TranslateModule, SafeHtmlPipe, JsonPipe]
})
export class BasketModalComponent implements OnInit, OnDestroy {
    @Input() brandId: string;
    @Input() venueId: string;
    @Input() message = false;
    @Input() sale : any;
    @Input() qty = 0;

    @Input() set addToCart(data) {
        this.addVoucher(data);
    }

    public faChevronLeft = faChevronLeft;

    public directories: any = JSON.parse(this.memoryStorage.getItem('directories'));
    public company: any = JSON.parse(this.memoryStorage.getItem('company'));
    public vouchersCopy: any[] = [];

    public form: UntypedFormArray;
    public lastVoucher: any;
    public payment = false;
    public totalVouchers = 0;
    public totalWrapping = 0;
    public totalDelivery = 0;
    public total = 0;
    public sale_preloaded = false;

    public show_confirmation: boolean = false;

    private formChangeSub: Subscription;

    constructor(
        private http: HttpService,
        private builder: UntypedFormBuilder,
        private memoryStorage: MemoryStorage
    ) { }

    ngOnInit() {

        if(this.sale && +this.sale.total_pending <= 0  && +this.sale.total_paid <= 0){
            //this.toPayment();
            this.sale_preloaded = true;
        }
        if(this.sale && +this.sale.total_pending + +this.sale.total_paid > 0){
            this.showConfirmation();
        }
        let vouchers = JSON.parse(this.memoryStorage.getItem('vouchers')) || [];
        try{
            vouchers = JSON.parse(localStorage.getItem('vouchers')) || vouchers;
        }catch{

        }

        this.form = this.builder.array([]);

        setTimeout(()=>{
            this.calculateTotal(),
            this.formChangeSub = this.form.valueChanges.pipe(startWith(this.form.getRawValue())).subscribe(this.calculateTotal.bind(this));
            vouchers.map(item=>this.addVoucher(item));
        })

        //if (this.message) this.lastVoucher = this.vouchers[0];
    }

    public cascadeChanges(): void {
        if (this.sale && this.sale?.vouchers){
            let cpt = 0;
            this.sale.vouchers.forEach(it=>{
                if(this.form && this.form.controls[cpt]){
                    this.form.controls[cpt].patchValue({voucher_id:it.voucher_id})
                }
                cpt++;
            })
            this.calculateTotal()
        }
    }

    ngOnDestroy() {
        if (this.formChangeSub) this.formChangeSub.unsubscribe();
    }

    public get header(): string {
        if ((+this.sale?.total_paid + +this.sale?.total_pending > 0) && !this.sale_preloaded && this.payment) return 'misc.sale_finalised';
        if (this.payment) return 'misc.payment';

        return 'misc.basket';
    }

    public close(): void {
        if (this.payment && !(+this.sale?.total_paid > 0 || +this.sale?.total_pending > 0)) this.payment = false;
        else document.querySelector('basket-modal').remove();
    }

    public toPayment(): void {
        this.payment = true;
        if(this.sale){
            delete this.sale.total_cost;
        } 
    }

    public setSale(sale: any): void {
        this.sale = sale;
        this.cascadeChanges();

        //console.log(sale.transactions, sale.transactions.length)

        if(!sale.payment_made || sale.transactions.length == 0) return;

        this.memoryStorage.removeItem('vouchers');
        try{
            localStorage.removeItem('vouchers');
        }catch{

        }
        this.sale_preloaded = false; //to show the confirmation screen
        this.showConfirmation();
    }

    public showConfirmation(): void{
        //fallback as it doesnt seem to work
        this.sale_preloaded = false; //to show the confirmation screen
        this.show_confirmation = true;
        this.payment = false;
    }

    public addVoucher(item: any): void {
        const disabled = !item.tag || item.tag === 'email';

        let fixed_image = false;

        let design = item?.image;

        if(item?.image) fixed_image = true;
        if(item.fixed_image !== undefined){
            fixed_image = item.fixed_image;
        }

        if(!item?.image){
            let filteredImages = this.company.images.filter(it=>it.caption==`wrap_option:${this.company.company_id}:email`)
            
            if(filteredImages.length == 0) filteredImages = this.company.images

            design = item.design || this.company.image || filteredImages[0]?.full_url || '';

            if (item.preset){
                design = item.preset?.image_url || '';
            } 
        }

        if(this.sale){
            if(this.sale?.vouchers)
                item.voucher_id = this.sale?.vouchers[this.form.length]?.voucher_id;
        }else{
            delete item.voucher_id
        }

        //console.log(item);
        this.form.push(this.builder.group({
            value: [{ value: item.value, disabled: true }],
            //value: [{ value: item.value, disabled: item?.preset?.voucherpreset_id ? true: false }],
            wrap_option_id: [item.wrap_option_id],
            mail_service_id: [item.mail_service_id],
            preset: [item.preset],
            name: [item.name],
            message: [item.message],
            title: [item.title],
            delivery_name: [{ value: item.delivery_name || '', disabled}],
            address_1: [{ value: item.address_1 || '', disabled }, Validators.required],
            address_2: [{ value: item.address_2 || '', disabled }],
            town: [{ value: item.town || '', disabled }, Validators.required],
            zip: [{ value: item.zip || '', disabled }, Validators.required],
            tag: [item.tag || 'email'],
            image: [design],
            design: [design],
            same_address: [false],
            fixed_image: [fixed_image],
            hideprice: [item?.hideprice || false],
            voucher_id: [item?.voucher_id || null],
            audio: [item.audio||'']
        }));
    }

    public deleteVoucher(index: number): void {
        this.form.removeAt(index);

        window.dispatchEvent(new Event('voucherDeleted'));
    }

    public downloadVoucher(): void {
        this.http.getFile('/vouchers', {
            params: {
                _form: 'pdf',
                redemption_code: this.sale.vouchers[0].redemption_code,
                no_intercept_response: '1'
            },
            headers: {
                'X-API-KEY': this.sale.guest_key
            }
        }).pipe(
        ).subscribe(res => {
            saveAs(res.body, `${this.sale.vouchers[0].redemption_code}.pdf`);
        });
    }

    private calculateTotal(): void {
        const vouchers = this.form.getRawValue();

        this.totalVouchers = vouchers.reduce((acc, item) => acc + item.value, 0);

        this.totalWrapping = vouchers.reduce((acc, item) => {
            const opt = this.directories.wrap_options.find(val => val.wrap_option_id === item.wrap_option_id);

            return acc + (opt ? +opt.price : 0);
        }, 0);

        this.totalDelivery = vouchers.reduce((acc, item) => {
            const opt = this.directories.mail_services.find(val => val.mail_service_id === item.mail_service_id);

            return acc + (opt ? +opt.price : 0);
        }, 0);

        this.total = this.totalVouchers + this.totalWrapping + this.totalDelivery;

        this.memoryStorage.setItem('vouchers', JSON.stringify(vouchers));
        try{
            localStorage.setItem('vouchers', JSON.stringify(vouchers));
        }catch{

        }
    }

    public get mode():string{
        return this.http.mode;
    }
}
