<div class="audio-recorder gn-bg-white">
   <p class="gn-text-lg">Audio Message</p>
  <div *ngIf="recordingSizeMB && !audioUrl">Processing...</div>
  <div *ngIf="recordingSizeMB && audioUrl">
    <p>The recipient will receive the following:</p>
    <div class="gn-flex gn-items-center gn-justify-center">
        <button mat-flat-button (click)="playAudio()" class="gm-w-full gn-text-black gn-bg-gray-200 gn-px-4 gn-py-2 gn-rounded-full">
        <fa-icon [icon]="faPlayIcn" class="gn-text-green-500 gn-mr-2"></fa-icon> Play
        </button>
        <button mat-flat-button (click)="resetRecording()" class="gm-w-full gn-text-black gn-bg-gray-200 gn-px-4 gn-py-2 gn-rounded-full" tooltip="Deletes the file.<br/> You can record again.">
        <fa-icon class="fa-solid gn-text-red-500" [icon]="faXmarkIcn"></fa-icon> Delete
        </button>
    </div>
    <!--button mat-flat-button (click)="uploadAudio()">Upload</button-->
    <!--div>Audio time: {{ recordingTime/10 }}s</div-->
    <p *ngIf="recordingSizeMB !== null" class="gn-text-sm gn-text-gray-500">
      Recording size: {{ recordingSizeMB }} MB
    </p>
  </div>
  <div *ngIf="!isRecording && !audioUrl && !recordingSizeMB">
    <div>Tap to record (Max: 15 seconds)</div>
    <button (click)="startRecording()" class="gn-text-white gn-bg-main gn-px-4 gn-py-2 gn-rounded-full">
      <fa-icon [icon]="faCircleIcn" class="gn-text-white gn-mr-2"></fa-icon> Record
    </button>
  </div>
  <div *ngIf="isRecording">
    <div>Recording in progress: {{ recordingTime }}s / 15</div>
    <button mat-flat-button (click)="stopRecording()" class="gn-text-black gn-bg-gray-200 gn-px-4 gn-py-2 gn-rounded-full">
      <fa-icon [icon]="faStopIcn" class="gn-text-black gn-mr-2"></fa-icon> Stop
    </button>
  </div>
</div>